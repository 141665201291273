import React from 'react'

import Layout from '../components/layout'

const ThanksPage = () => (
  <Layout>
    <div className="container vh-75 dt w-100">
      <div className="dtc v-mid tc ph3 ph2-l tc">
        <span className="f2" role="img" aria-label="waving hand">👋 </span>
        <div className="f5 f3-l f2-m fw6 tc pt3">
          Thanks! Email received. We&#8217;ll be in touch soon.
        </div>
      </div>
    </div>
  </Layout>
)

export default ThanksPage
